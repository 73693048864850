<template>
  <section class="wrap">
    <dl class="Nbanner">
      <dt>拥抱产业互联网赋能行业信息化</dt>
      <dd>高效的开发团队超出用户期待</dd>
      <dd class="NBbtn"><a href="javascript:;">开启互联网新生活</a></dd>
    </dl>
  </section>
  <section class="wrap">
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">企业案例<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <article class="Lcase">
      <dl>
        <dd><img src="../assets/images/case1.png" alt=""></dd>
        <dt>
          <em class="icon-wulianwang"></em>
          <h5>平台级应用</h5>
          <h6>进口汽车供应链SaaS平台</h6>
          <div><span>客户</span><p>全国进口汽车的海运、报关、仓储、开证等供应链上的各类传统企业。</p></div>
          <div><span>模式</span><p>以SaaS的模式打造S2B2C商业模式，打通供应链各环节的数据壁垒，实现数据互联互通。</p></div>
          <div><span>价值</span><p>运用图像识别、云计算、移动办公等互联网技术为传统企业重构商业模式，建立企业工作规范，实现数据标准统一，减员增效。</p></div>
        </dt>
      </dl>
      <dl>
        <dd><img src="../assets/images/case2.png" alt=""></dd>
        <dt>
          <em class="icon-qukuailian"></em>
          <h5>平台级应用</h5>
          <h6>进口汽车供应链SaaS平台</h6>
          <div><span>客户</span><p>全国进口汽车的海运、报关、仓储、开证等供应链上的各类传统企业。</p></div>
          <div><span>模式</span><p>以SaaS的模式打造S2B2C商业模式，打通供应链各环节的数据壁垒，实现数据互联互通。</p></div>
          <div><span>价值</span><p>运用图像识别、云计算、移动办公等互联网技术为传统企业重构商业模式，建立企业工作规范，实现数据标准统一，减员增效。</p></div>
        </dt>
      </dl>
      <dl>
        <dd><img src="../assets/images/case3.png" alt=""></dd>
        <dt>
          <em class="icon-yunwendang"></em>
          <h5>平台级应用</h5>
          <h6>进口汽车供应链SaaS平台</h6>
          <div><span>客户</span><p>全国进口汽车的海运、报关、仓储、开证等供应链上的各类传统企业。</p></div>
          <div><span>模式</span><p>以SaaS的模式打造S2B2C商业模式，打通供应链各环节的数据壁垒，实现数据互联互通。</p></div>
          <div><span>价值</span><p>运用图像识别、云计算、移动办公等互联网技术为传统企业重构商业模式，建立企业工作规范，实现数据标准统一，减员增效。</p></div>
        </dt>
      </dl>
      <dl>
        <dd><img src="../assets/images/case4.png" alt=""></dd>
        <dt>
          <em class="icon-shuju"></em>
          <h5>平台级应用</h5>
          <h6>进口汽车供应链SaaS平台</h6>
          <div><span>客户</span><p>全国进口汽车的海运、报关、仓储、开证等供应链上的各类传统企业。</p></div>
          <div><span>模式</span><p>以SaaS的模式打造S2B2C商业模式，打通供应链各环节的数据壁垒，实现数据互联互通。</p></div>
          <div><span>价值</span><p>运用图像识别、云计算、移动办公等互联网技术为传统企业重构商业模式，建立企业工作规范，实现数据标准统一，减员增效。</p></div>
        </dt>
      </dl>
    </article>
  </section>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    this.$emit('getBgClass', "caseBg");
  }
}
</script>
